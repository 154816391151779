<template>
  <div>
    <div class="title flex align-center">
      <div class="flex align-center title"><span></span>一字一句 无人知晓</div>
    </div>

    <div class="main">
      <mavon-editor
        v-model="data.content"
        ref="markdown"
        style="height: 40vh; width: 35vw"
        :subfield="false"
        @change="change"
        :toolbars="toolbars"
      />
      <div class="picyure">
        <el-upload
          class="upload"
          drag
          action="https://node.isujin.cn/admin/upload"
          :on-error="uploadError"
          name="head_img"
          :on-success="handleAvatarSuccess"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imgUrl" :src="imgUrl" class="avatar" />
          <i class="iconfont icon-jurassic_image" v-if="!imgUrl"></i>
          <div class="el-upload__text" v-if="!imgUrl">
            上传自定义背景图片 (680*440)
          </div>
        </el-upload>
        <el-button class="submitpicture" type="primary" @click="setpicture"
          >随机背景</el-button
        >
      </div>
    </div>
    <el-select v-model="value" placeholder="请选择类别" class="select">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        class="option"
      >
      </el-option>
    </el-select>
    <!-- <section>
            <Date @getDate="getDate" :originalDate="data.time" />
        </section> -->

    <el-button class="submit" type="primary" @click="submit">SUBMIT</el-button>
    <div v-loading.fullscreen.lock="loading"></div>
  </div>
</template>

<script>
import Date from "../../components/date.vue";
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";
export default {
  components: {
    mavonEditor,
  },
  data() {
    return {
      loading: false,
      imgUrl: "",
      options: [
        {
          value: "https://www.ny08.cn/usr/uploads/time/61f5541f9b439.jpg",
          label: "图片",
        },
        {
          value: "https://www.ny08.cn/usr/uploads/time/61f55435ace27.jpg",
          label: "闲谈",
        },
        {
          value: "https://www.ny08.cn/usr/uploads/time/61f5544903a4d.jpg",
          label: "摘录",
        },
        {
          value: "https://www.ny08.cn/usr/uploads/time/61f5544903a4d.jpg",
          label: "随记",
        },
        {
          value: "https://www.ny08.cn/usr/uploads/time/61f5544903a4d.jpg",
          label: "技术",
        },
      ],
      value: "",
      data: {
        id: "",
        content: "", // 内容
        contentHtml: "", // 内容解析html
        valuel: "", //选择类别
        // time: '',               // 时间\
        backimg: "",
      },
      id: null,
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        // strikethrough: true,// 中划线
        // mark: true,         // 标记
        // superscript: true,  // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        // ol: true,           // 有序列表
        // ul: true,           // 无序列表
        // link: true,         // 链接
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        // alignright: true,   // 右对齐
        subfield: true, // 单双栏模式
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getMesDetail();
    }
    this.getpicture();
  },
  methods: {
    getMesDetail() {
      this.$axios
        .get("/admin/getmesinfo", {
          params: {
            mes_id: this.$route.params.id,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            const detail = res.data.data[0];
            this.data.content = detail.content;
            this.imgUrl = detail.backimg;
            this.value = detail.icon;
            console.log(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleAvatarSuccess(res, file) {
      console.log(res.data);
      this.imgUrl = res.data;
      this.$message.success("图片上传成功");
    },
    setpicture() {
      const min = 5000;
      const max = 6124;
      const id = Math.floor(min + Math.random() * (max - min));
      this.$axios
        .get(`http://v3.wufazhuce.com:8000/api/onelist/${id}/0`)
        .then((res) => {
          console.log(res.data.data);
          this.imgUrl = res.data.data.content_list[0].img_url; // 给提交
          console.log(this.data.backimg);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //         getpicture() {
    //     const min  = 5000;
    //     const max  = 6124;
    //     const id = Math.floor(min+Math.random()*(max-min));
    //   this.$axios
    //     .get(`http://v3.wufazhuce.com:8000/api/onelist/${id}/0`)
    //     .then((res) => {
    //       console.log(res.data.data);
    //         this.data.backimg = res.data.data.content_list[0].img_url;
    //         console.log(this.data.backimg);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    // load(id) {
    //     this.$request(() => this.$http.get(`envelope/${id}`)
    //         .then(res => {
    //             if (res.data.status === 1) {
    //                 this.data = res.data.body
    //             } else {
    //                 this.$message.error(res.data.body.message)
    //             }
    //         })
    //     )
    // },
    getDate(time) {
      this.data.time = time;
    },
    change(value, render) {
      this.data.contentHtml = render; // render 为 markdown 解析后的结果[html]
      this.data.content = value; // 输入的内容
    },
    submit() {
      // if (!this.data.content || !this.data.time) {
      //     this.$message.error('请填写完整的信息')
      //     return
      // }
      // const type = this.id ? `/envelope/${this.data._id}` : '/envelope'
      // const message = this.id ? '修改成功' : '发布成功'

      // this.$request(() => this.$http.post(type, this.data).then(res => {
      //     this.$message.success(message)
      //     this.$router.push('/envelope')
      //     this.$infoUpdate()
      // }))
      this.data.backimg = this.imgUrl;
      this.data.valuel = this.value;
      this.data.id = this.$route.params.id;
      this.loading = true;
      if (this.$route.params.id) {
        this.$axios
          .post("/admin/updatemes", { content: this.data })
          .then((res) => {
            // console.log(res);
            if (res.data.code === 0) {
              this.$message({
                message: "更新成功",
                type: "success",
              });
              setTimeout(() => {
                this.loading = false;
                this.$router.push({ name: "Message" });
              }, 1400);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.data.backimg = this.imgUrl;
        this.data.valuel = this.value;
        console.log(this.data);
        this.$axios
          .post("/admin/addmes", { data: this.data })

          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$message.success("添加闲言成功");
              setTimeout(() => {
                this.loading = false;
                this.$router.push({ name: "Message" });
              }, 1000);
              // this.load()
              // setTimeout(() => {
              //   location.reload()
              // }, 1400);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
section {
  margin: 15px 0;
  .el-input {
    width: 100%;
    height: 38px;
    margin: 5px 0;
    ::v-deep input {
      border-color: #eee !important;
    }
  }
}
.markdown-body {
  height: 70vh;
  box-shadow: none !important;
  border: 1px solid #eee !important;
}

.select {
  margin-top: 30px;
}

.submit {
  margin-top: 40px;
  width: 100px;
  height: 40px;
  font-size: 14px;
  color: #fff;
  display: flex;
}
.submitpicture {
  margin-top: 20px;
  margin-left: 90px;
  width: 100px;
  height: 40px;
  font-size: 14px;
  color: #fff;
}
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload {
  margin-left: -40px;
  &:first-of-type {
    margin-right: 20px;
  }
  &:hover {
    ::v-deep .el-upload-dragger {
      border-color: #409eff;
      .iconfont,
      .el-upload__text {
        color: #409eff;
      }
    }
  }
  .avatar {
    width: 100%;
    height: 100%;
  }
  .iconfont {
    color: #a2a3a5;
    font-size: 34px;
    margin-bottom: 10px;
    transition: all 0.3s;
  }
  ::v-deep .el-upload-dragger {
    width: 400px;
    height: 250px;
    transition: all 0.3s;
    border-color: #dcdfe6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .el-upload__text {
      color: #a2a3a5;
      transition: all 0.3s;
    }
  }
}

@media screen and (max-width: 600px) {
  .markdown-body {
    height: 60vh !important;
    box-shadow: none !important;
  }
  section {
    margin: 10px 0;
    box-shadow: none;
  }
  .submit {
    width: 150px;
    height: 36px;
    margin: 0 0 20px;
    line-height: 36px;
    padding: 0;
  }
}
</style>