<template>
  <div>
         <div class="title flex align-center">
      <div class="flex align-center"><span></span>是一个什么样的人呢</div>
    </div>
    <Markdown style="height: 700px" @contentChange="info.aboutMe = $event" :defaultContent="info.aboutMe" />
    <div class="submit flex align-center">
      <el-button class="subBtn" type="primary" icon="el-icon-position" @click="saveAboutMe">保存</el-button>
    </div>
  </div>
</template>

<script>
import Markdown from '../../components/markdown.vue'
export default {
  components: { Markdown },
  data () {
    return {
      info: {
        aboutMe: ''
      }
    }
  },
  created () {
    this.getAbouDetail()
  },
  methods: {
    getAbouDetail(){
this.$axios
        .get("/front/getaboutme")
        .then((res) => {
          console.log(res);
          console.log(res.data.data);
           
          if (res.data.code == 0) {
          console.log(res.data.data);
          this.info.aboutMe = res.data.data[0].content
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    saveAboutMe () {
      if (!this.info.aboutMe) {
        this.$message.error('内容不能为空的~')
      }
          this.$axios
            .post("/admin/updateme", { data:this.info.aboutMe })
            .then((res) => {
              console.log(res);
              if (res.data.code == 0) {
                   this.$message.success('修改成功')  
              }
            })
            .catch((e) => {
              console.log(e);
            });
    } 
  }
}
</script>

<style lang="scss" scoped>
  .submit {
    justify-content: flex-start;
    margin-top: 20px;
  }
</style>