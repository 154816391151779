<template>
  <div class="content">
    <div class="title flex align-center">
      <div class="flex align-center">
        <span></span
        >无所谓好或不好,能写下这么多字也是不可思议。希望若干年以后，它们可以再一次让过去变得很温柔。
      </div>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <el-form-item prop="title">
        <el-input v-model.trim="ruleForm.title" placeholder="标题"></el-input>
      </el-form-item>
      <el-form-item prop="description">
        <el-input
          v-model.trim="ruleForm.description"
          placeholder="描述"
        ></el-input>
      </el-form-item>
      <el-form-item prop="musicName">
        <el-input
          v-model.trim="ruleForm.musicName"
          placeholder="音乐名称"
        ></el-input>
      </el-form-item>

      <el-form-item prop="musicName">
        <el-input
          v-model.trim="ruleForm.musicid"
          placeholder="音乐ID"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="imgs flex align-center">
      <el-upload
        class="upload"
        drag
        action="https://node.isujin.cn/admin/upload"
        :on-error="uploadError"
        name="head_img"
        :on-success="handleAvatarSuccess"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="imgUrl" :src="imgUrl" class="avatar" />
        <i class="iconfont icon-jurassic_image" v-if="!imgUrl"></i>
        <div class="el-upload__text" v-if="!imgUrl">封面图片 (680*440)</div>
      </el-upload>
      <el-upload
        class="upload"
        drag
        :show-file-list="false"
        action="https://node.isujin.cn/admin/upload"
        :headers="token"
        :on-error="uploadError"
        name="head_img"
        :on-success="handleMusicSuccess"
        :before-upload="beforeMusicUpload"
      >
        <i class="iconfont icon-Music1"></i>
        <div class="el-upload__text">{{ musicText }}</div>
      </el-upload>
    </div>
    <Markdown
      @contentChange="contentChange"
      v-model="ruleForm.content"
      style="height: 400px"
    />
    <div class="submit flex align-center">
      <el-button
        class="subBtn"
        type="primary"
        icon="el-icon-position"
        @click="addArticle"
        >发布</el-button
      >
    </div>
    <div v-loading.fullscreen.lock="loading"></div>
  </div>
</template>

<script>
import Markdown from "../../components/markdown.vue";
export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        title: "",
        description: "",
        musicName: "",
        musicid: "",
        content: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 0, max: 20, message: "不能超过20个字", trigger: "blur" },
        ],
      },
      imgUrl: "",
      // musicUrl: "",
      // musicText: "背景音乐",
      token: {
        authorization: "bearer " + localStorage.getItem("token"),
      },
    };
  },
  components: { Markdown },
  //   computed: { ...mapState(['userInfo']) },
  methods: {
    beforeAvatarUpload(file) {
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.error("上传封面图片大小不能超过 4MB!");
      }
      return isLt4M;
    },
    handleAvatarSuccess(res, file) {
      this.imgUrl = res.data;
      this.$message.success("图片上传成功");
    },
    // beforeMusicUpload(file) {
    //   const isLt4M = file.size / 1024 / 1024 < 8;
    //   if (!isLt4M) {
    //     this.$message.error("上传音频大小不能超过8MB!");
    //   }
    //   isLt4M && (this.musicText = file.name);
    //   return isLt4M;
    // },
    // handleMusicSuccess(res, file) {
    //   this.musicUrl = res.data;
    //   this.$message.success("音频上传成功");
    // },
    contentChange(e) {
      this.ruleForm.content = e;
    },
    addArticle() {
      this.loading = true;
      if (this.$route.params.id) {
        this.$axios
          .post("/admin/update", {
            title: this.ruleForm.title,
            content: this.content,
            id: this.$route.params.id, // 把这些传给后端
            description: this.ruleForm.description,
            imgUrl: this.imgUrl,
            musicName: this.ruleForm.musicName,
            musicUrl: this.ruleForm.musicid,
            content: this.ruleForm.content,
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code === 0) {
              this.$message({
                message: "更新成功",
                type: "success",
              });
              setTimeout(() => {
                this.loading = false;
                // this.$router.push({ name: "AltList" });
              }, 1400);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (!this.imgUrl) {
              this.$message.error("封面图片不能为空");
              return;
            }
            if (!this.ruleForm.content) {
              this.$message.error("文章内容不能为空");
              return;
            }
            this.$axios
              .post("/admin/addarticle", {
                data: {
                  ...this.ruleForm,
                  imgUrl: this.imgUrl,
                  musicUrl: this.musicid,
                  content: this.ruleForm.content,
                  musicUrl: this.ruleForm.musicid,
                },
              })
              .then((res) => {
                if (res.data.code === 0) {
                  this.$message.success("添加文章成功");
                  setTimeout(() => {
                    this.loading = false;
                    this.$router.push({ name: "AltList" });
                  }, 1000);
                }
              })
              .catch((e) => {
                console.log(e);
              });
            // const data = {
            //     ...this.ruleForm,
            //   imgUrl: this.imgUrl,
            //   musicUrl: this.musicUrl,
            //   content: this.ruleForm.content
            // }
            // add(data).then(res => {
            //   this.$message.success('添加文章成功')
            // })
          }
        });
      }
    },
    uploadError(e) {
      this.$message.error(JSON.parse(e.message).msg);
    },
    // 获取博客详情
    getDetail() {
      this.$axios
        .get("/front/detail", {
          params: {
            article_id: this.$route.params.id,
          },
        })
        .then((res) => {
          console.log(res);

          if (res.data.code == 0) {
            const detail = res.data.data;
            console.log(this.ruleForm.content);
            this.ruleForm.title = detail.title;
            this.ruleForm.content = detail.content;
            this.ruleForm.description = detail.description;
            this.ruleForm.musicName = detail.musicName;
            this.ruleForm.musicid = detail.musicUrl;
            this.imgUrl = detail.imgUrl;
            this.musicText = detail.musicName;
            console.log(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    if (this.$route.params.id) {
      //判断路由是否带有id，就是更新页面
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.ruleForm {
  margin-top: 40px;
}
.imgs {
  display: flex;
  align-items: center;
}
.upload {
  &:first-of-type {
    margin-right: 20px;
  }
  &:hover {
    ::v-deep .el-upload-dragger {
      border-color: #409eff;
      .iconfont,
      .el-upload__text {
        color: #409eff;
      }
    }
  }
  .avatar {
    width: 100%;
    height: 100%;
  }
  .iconfont {
    color: #a2a3a5;
    font-size: 34px;
    margin-bottom: 10px;
    transition: all 0.3s;
  }
  ::v-deep .el-upload-dragger {
    width: 400px;
    height: 250px;
    transition: all 0.3s;
    border-color: #dcdfe6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .el-upload__text {
      color: #a2a3a5;
      transition: all 0.3s;
    }
  }
}

.submit {
  justify-content: flex-start;
  margin-top: 20px;
}
</style>
