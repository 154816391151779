<template>
  <div>
    <div class="title flex align-center">
      <div class="flex align-center"><span></span>友链列表 ({{ total }})</div>
    </div>
    <el-button type="primary" class="addBtn" @click="toaddlink">
      新增+</el-button
    >
    <el-table :data="items" class="table">
      <el-table-column prop="name" label="名称" width="100"></el-table-column>
      <el-table-column label="头像" width="85">
        <template slot-scope="scope">
          <img
            :src="scope.row.icon"
            alt=""
            style="width: 45px; height: 45px; border-radius: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column prop="site" label="地址" width="200"></el-table-column>
      <el-table-column
        prop="description"
        label="描述"
        width="220"
      ></el-table-column>
      <el-table-column
        prop="createdAt"
        label="创建时间"
        width="170"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            icon="el-icon-edit"
            @click="$router.push(`/addlink/${scope.row.id}`)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            icon="el-icon-delete"
            @click="remove(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          _id: "61e833f0f98838152e361c18",
          name: "南烟万事屋",
          site: "http://www.ny08.cn",
          description: "一个独立的博客",
          icon: "http://localhost:3000/uploads/578ce70eeca8c4d471b18cba8b3b1645",
          __v: 0,
        },
        {
          _id: "61f23d9dcb14a01e7e558d42",
          name: "123",
          site: "123",
          description: "123",
          icon: "http://localhost:3000/uploads/2dbee137022b1a032b4a657f68492262",
          __v: 0,
        },
        {
          _id: "61f23da8cb14a01e7e558d48",
          name: "123",
          site: "123",
          description: "123",
          icon: "http://localhost:3000/uploads/6f356364d8c79f61484418d550fe8425",
          __v: 0,
        },
      ],
    };
  },
  methods: {
    toaddlink() {
      this.$router.push({ name: "AddLink" });
    },
    async fetch() {
      // const res = await this.$http.get("rest/links");
      // this.items = res.data;
      this.$axios
        .get("/admin/linklist")
        .then((res) => {
          console.log(res);

          if (res.data.code == 0) {
            this.items = res.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    remove(row) {
      // this.$confirm("是否确定要删除该友链?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(async () => {
      //     await this.$http.delete(`rest/links/${id}`);
      //     this.$message({
      //       type: "success",
      //       message: "删除成功!",
      //     });
      //     this.fetch();
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消删除",
      //     });
      //   });
      let id = row.id;
      this.$confirm("此操作将永久删除该友链, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //请求删除api todo
          this.$axios
            .post("/admin/dellink", { link_id: id })
            .then((res) => {
              console.log(res);

              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: `${id}友链删除成功`,
                });
                this.fetch();
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  created() {
    this.fetch();
  },
};
</script>
<style lang="scss" scoped>
.table {
  margin-top: 30px;
}
.addBtn {
  float: right;
  margin-top: -35px;
}
</style>
