<template>
<div>
     <div class="title flex align-center">
      <div class="flex align-center"><span></span>评论列表 (100)</div>
    </div>
      <el-table
    :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
     :header-row-style="{background: 'none'}"
   
   >
    <el-table-column
      label="姓名"
      width="120"
      >
      
      <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium">{{ scope.row.name }}</el-tag>
          </div>
      </template>
    </el-table-column >
     <el-table-column
      label="内容"
      width="300"
      >
      <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
           <span style="margin-left: -10px">{{ scope.row.content }}</span>
          </div>
      </template>
    </el-table-column>
     <el-table-column
      label="日期"
      width="230">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span style="margin-left: 10px">{{ scope.row.createdAt }}</span>
      </template>
    </el-table-column>
      <el-table-column
      label="邮箱"
      width="200"
      >
      <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
           <span>{{ scope.row.email }}</span>
          </div>
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
   <!-- <div class="pagi">
      <el-pagination background layout="prev, pager, next" @current-change="currentChange" :total="total"></el-pagination>
    </div> -->
    <el-pagination
  background
  @current-change="handleCurrentChange"
  
    :page-size="pageSize"
  layout="prev, pager, next"
  :total="1000">
</el-pagination>
</div>
</template>

<script>
  export default {
    data() {
      return {
        currentPage: 1,  // 当前页码
        pageSize: 8,    
        tableData: [
          ]
      }
    },
    methods: {
       handleCurrentChange(val) {
    this.currentPage = val;
  },
      handleEdit(index, row) {
        console.log(index, row);
      },
      handleDelete(index, row) {
        let id = row.id;
      this.$confirm("此操作将永久删除该⽂章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //请求删除api todo
          this.$axios
            .post("/admin/delecom", { comment_id: id })
            .then((res) => {
              console.log(res);

              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: `${id}评论删除成功`,
                });
                setTimeout(() => {
                  location.reload()
                }, 1400);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      },
      comadmin(){
         this.$axios
         .get("/admin/comadmin")
         .then((res)=>{
           console.log(123);
           if(res.data.code == 0){
             this.tableData = res.data.data
             this.
             console.log(tableData);
           }
         })

      }

    },
    created () {
      this.comadmin()
    }
  }

</script>

<style lang="scss" scoped>
  ::v-deep.el-table,  ::v-deep.el-table tr,  ::v-deep.el-table tr th {
    background: none !important;
  }
  .tables {
    margin-top: 40px;
  }
  .pagi {
    margin-top: 30px;
  }
::v-deep .el-pagination{
   margin-top: 30px;
   margin-left: 26%;
}


</style>