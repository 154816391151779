<template>
    <div class="phrase">
         <div class="title flex align-center">
      <div class="flex align-center"><span></span>闲言管理</div>
         </div>
          <el-button type="primary" class="addBtn" @click="newEnvelope">
        新增</el-button
      >

        <el-table :data="data" style="width: 100%"  height="calc(800px - 240px)" class="table">
            
            <el-table-column label="Content">
                <template slot-scope="scope">
                    <p>{{ scope.row.content }}</p>
                </template>
            </el-table-column>

            <el-table-column label="Date" width=175>
                <template slot-scope="scope">
                    <span>{{ (scope.row.createdAt) }}</span>
                </template>
            </el-table-column>

            <el-table-column label="options" width=100>
                <template slot-scope="scope">
                    <el-tooltip 
                        effect="dark" 
                        placement="top"
                        v-for="(item, index) in list"
                        :content="item.text"
                        :key="index"
                    >
                        <i :class="item.icon" @click="option(scope.row,index)"></i>
                    </el-tooltip>
                </template>
            </el-table-column>

        </el-table>

        <Pagination :data="total" :page="page" @update="load" />

    </div>
</template>

<script>
// import Pagination from '@/components/Pagination'
export default {
    // components: { 
    //     Pagination
    // },
    data() {
        return {
            data: [
    {
        "_id": "61e833f0f98838152e361c18",
        "content": "南烟万事屋",
        "time": "2022.1.3",
        "description": "一个独立的博客",
        "icon": "http://localhost:3000/uploads/578ce70eeca8c4d471b18cba8b3b1645",
        "__v": 0
    },
            ],
            total: 0,
            page: 1,
            list: [
                {
                    text: 'Edit',
                    icon: 'el-icon-edit'
                },
                {
                    text: 'Delete',
                    icon: 'el-icon-delete'
                },
                
            ]
        }
    },
    created() {
        this.load()
    },
    methods: {
        newEnvelope() {
            this.$router.push('/addmess')
        },
        load(page){
            // this.$request(() => this.$http.get('/envelope', {
            //     params: { page }
            // }).then(res => {
            //     ['data', 'total', 'page'].map(i => this[i] = res.data.body[i])
            // }))
              this.$axios
         .get("/admin/meslist")
         .then((res)=>{
           if(res.data.code == 0){
             this.data = res.data.data
             console.log(data);
           }
         })

        },
        option(row,index) {
             const o = {
                0: () => {
                   this.$router.push(`/AddMess/${row.id}`)
                },
                1: () => {
                            console.log(row);
                let id = row.id;
      this.$confirm("此操作将永久删除该闲言, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //请求删除api todo
          this.$axios
            .post("/admin/delemes", { mes_id: id })
            .then((res) => {
              console.log(res);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: `${id}闲言删除成功`,
                  
                });
                this.load()
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
                }
            }
            o[index]()    
        
                }
    }
}
</script>

<style lang="scss" scoped>
.phrase{
    .header{
        h1{
            border-left: 2px solid #0084ff;
            padding-left: 16px;
            font-size: 18px;
            font-weight: 400;
            margin: 20px 0 30px;
            color: #0084ff;
        }
        .info{
            display: flex;
            margin: 15px 0;
            div{
                margin-right: 10px;
            }
        }
    }
    .phrase-list{
        width: 100%;
        thead tr{
            height: 40px;
        }
        tbody{
            tr{
                height: 50px;
            }
        }
    }
    i[class*=el-icon-]{
        cursor: pointer;
        margin: 2px;
        padding: 2px;
        font-size: 14px;
        color: #cfcfcf;
        transition: all .2s;
        &:hover{
            color: #0e8bff;
        }
        &.el-icon-delete:hover{
            color: red;
        }
    }
    ::v-deep .el-table__body-wrapper{
        &::-webkit-scrollbar-track {
            background: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef7ff;
        }
        &::-webkit-scrollbar {
            width: 6px;
        }
    }

}

.table{
  margin-top: 30px;
}
  .addBtn {
    float: right;
    margin-top: -35px;
  }
@media screen and (max-width: 600px) {
    .phrase{
        padding: 0;
        box-shadow: none;
        .header h1{
            font-size: 16px;
            margin: 10px 0 20px;
        }
    }
    .el-table__header, .el-table__body{
        width: 100% !important;
        display: block;
        tbody{
            display: block;
        }
        thead{
            display: block;
            tr{
                display: flex;
                th{
                    padding: 6px 0;
                    &:nth-of-type(2){
                        display: none;
                    }
                    &:nth-of-type(1){
                        flex: 1;
                    }
                    &:nth-of-type(3){
                        width: 68px;
                        text-align: right;
                    }
                    .cell{
                        padding: 0;
                    }
                } 
            }
        }
        .el-table__row{
            display: flex;
            width: 100%;
            justify-content: space-between;
            td{
                &:nth-of-type(2){
                    display: none;
                }
                &:nth-of-type(1){
                    flex: 1;
                }
                &:nth-of-type(3){
                    width: 68px;
                    text-align: right;
                }
                div{
                    padding: 0;
                }
            }
            .el-button{
                display: none;
            } 
            .el-icon-edit, .el-icon-delete{
                display: inline-block;
                margin: 4px;
                color: #0084ff;
                font-size: 16px;
                &.el-icon-delete{
                    color: red;
                }
            }
        } 
    }
    
}
</style>