<template>
  <div class="markDown">
    <mavon-editor
      :subfield="true"
      @save="mkSave"
      @change="mkChange"
      ref="md"
      @imgAdd="imgAdd"
      @imgDel="imgDel"
      v-model="content"
      action="https://node.isujin.cn/admin/upload"
    />
  </div>
</template>

<script>
export default {
  props: {
    defaultContent: {
      type: String,
      default: "",
    },
  },
  // computed: { ...mapState(['userInfo']) },
  data() {
    return {
      content: "",
    };
  },
  watch: {
    defaultContent: {
      handler(newval) {
        this.content = newval;
      },
    },
  },
  methods: {
    mkChange(val, ren) {
      this.content = val;
      this.$emit("contentChange", this.content);
    },
    mkSave(val, ren) {
      this.content = val;
      this.$emit("contentChange", this.content);
    },
    async imgAdd(pos, $file) {
      console.log(123);
      const formdata = new FormData();
      formdata.append("file", $file);
      try {
        // const res = await uploadImg
        this.$axios
          .post("admin/uploadmkimg", {
            data: formdata.get("file").miniurl,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              const url = res.data.data;
              this.$refs.md.$imglst2Url([[pos, url]]);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {}
    },
    imgDel() {
      console.log("删除图片");
    },
    getDetail() {
      this.$axios
        .get("/front/detail", {
          params: {
            article_id: this.$route.params.id,
          },
        })
        .then((res) => {
          console.log(res);

          if (res.data.code == 0) {
            const detail = res.data.data;
            this.content = detail.content;
            console.log(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    if (this.$route.params.id) {
      //判断路由是否带有id，就是更新页面
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.markDown {
  margin-top: 30px;
  height: 600px;
  .v-note-wrapper {
    height: 100%;
  }
}
</style>